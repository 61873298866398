import { makeStyles } from '@material-ui/core';
import { SkycellThemeInterface } from 'app/themes/skycellThemeInterface';

const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    switchWrapper: {
        width: '100%',
        height: '100%',
        color: theme.palette.common.black,
        backgroundColor: theme.palette.primary[30],
        borderRadius: '8px',
        font: 'normal normal 400 14px/14px Roboto',
        justifyContent: 'space-between',
        maxHeight: '40px',
        maxWidth: '440px',

        '&+.MuiSwitch-track': {
            backgroundColor: 'white',
        },

        '&>div:first-of-type': {
            margin: '0 5px !important',
        },
    },
    disabled: {
        backgroundColor: theme.palette.common.secondShadeOfGray,
    },
    switch: {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: theme.palette.common.white,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.deepBlue,
        },
        '& .MuiSwitch-switchBase': {
            color: theme.palette.common.white,
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            color: theme.palette.secondary[200],
        },
        '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
            color: theme.palette.common.white,
        },
        '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: '#0000001F',
            opacity: 1,
        },
    },
}));

export default useStyles;
