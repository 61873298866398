import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TemperatureChart from 'app/components/TemperatureChart';
import { trimTemperatureReadoutSensorData } from 'app/utils/tools';
import { SensorDataResponse } from 'app/dataTypes/SecureBackend/apiResponse/SensorData';
import TemperatureCheck from 'app/shared-components/TemperatureCheck';
import { useTheme } from '@material-ui/core';
import { dateTimeMaskUTC } from 'app/utils/constants';
import moment from 'moment';
import useStylesTemperatureInfo from '../TemperatureInfo.style';
import useStyles from './TemperatureReadout.style';
import MeasuredTemperatureSwitch from '../MeasuredTemperatureSwitch';

export type TemperatureReadoutData = {
    notFound: boolean,
    sensorDataResponse?: SensorDataResponse,
    serialNumber?: string,
    splitMeasurementsAndPredictions?: boolean,
    onChangeSplitMeasurementsAndPredictions?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
} | null;

export const TemperatureReadout = ({
    notFound,
    sensorDataResponse,
    serialNumber = '',
    splitMeasurementsAndPredictions = false,
    onChangeSplitMeasurementsAndPredictions = () => {},
}: TemperatureReadoutData) => {
    const classesTemperatureInfo = useStylesTemperatureInfo();
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const labelData = useMemo(() => ({
        positions: sensorDataResponse?.positions,
        dataTypes: sensorDataResponse?.dataTypes,
        loggerTypes: sensorDataResponse?.loggerTypes,
    }), [sensorDataResponse]);

    const updatedSensorData = useMemo(() => {
        return trimTemperatureReadoutSensorData(sensorDataResponse?.data || []);
    }, [
        sensorDataResponse,
    ]);

    const [fromDate, toDate, temperatureMin, temperatureMax] = useMemo<[string | null, string | null, number, number]>(
        () => {
            const temps = updatedSensorData?.map(({ d }) => d[0] ?? d?.[1]);
            const temperatureMin = temps.reduce((acc, curr) => {
                return Math.min(acc, curr ?? Infinity);
            }, Infinity);

            const temperatureMax = temps?.reduce((acc, curr) => {
                return Math.max(acc, curr ?? -Infinity);
            }, -Infinity);

            return [
                moment().subtract(1, 'days').utc(false).format(dateTimeMaskUTC),
                moment().utc(false).format(dateTimeMaskUTC),
                temperatureMin,
                temperatureMax,
            ];
        }, [updatedSensorData],
    );

    return (
        <div style={{ flex: 1 }}>
            <div className={classesTemperatureInfo.topScreenWrapper}>
                <div className={classesTemperatureInfo.topScreen}>
                    <span className={classesTemperatureInfo.containerText}>{t('CONTAINER')}</span>
                    <span className={classesTemperatureInfo.serialNumber}>{serialNumber}</span>

                    {
                        !notFound && sensorDataResponse
                            ? (
                                <>
                                    <div className={classes.temperatureCheckBody}>
                                        <span style={{ color: theme.palette.secondary[600] }}>
                                            {t('TEMPERATURE.LAST_24_RANGE')}
                                        </span>
                                        <span style={{ color: theme.palette.secondary.dark }}>
                                            <TemperatureCheck
                                                temperatureRangeMin={temperatureMin}
                                                temperatureRangeMax={temperatureMax}
                                                temperatureMin={temperatureMin}
                                                temperatureMax={temperatureMax}
                                                grid={false}
                                                isNoStyleText
                                            />
                                        </span>
                                    </div>

                                    <MeasuredTemperatureSwitch
                                        value={splitMeasurementsAndPredictions}
                                        onChange={onChangeSplitMeasurementsAndPredictions}
                                        className={classes.measuredTemperatureSwitch}
                                    />

                                    <TemperatureChart
                                        labelData={labelData}
                                        data={updatedSensorData}
                                        shipmentStart={fromDate}
                                        shipmentEnd={toDate}
                                        containerType={null}
                                        yMinMaxCustom={{ min: temperatureMin - 2, max: temperatureMax + 2 }}
                                    />
                                </>
                            )
                            : (
                                <div className={classes.noData}>
                                    <span className="title">{t('NO_DATA_AVAILABLE')}</span>
                                    <span className="description">{t('NO_TEMPERATURE_DATA')}</span>
                                    <span className="description">
                                        <br />
                                    </span>
                                    <span className="description">{t('CHECK_AGAIN_LATER')}</span>
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    );
};
